import * as React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {Flex, Box, Button, VStack} from "@chakra-ui/react";
import {MdPhone, MdEmail, MdLocationOn} from "react-icons/md";

import Layout from "../components/Layout";
import PageBody from "../components/PageBody";
import ContactForm from "../components/ContactForm";
import FeatureCard from "../components/FeatureCard";

// import WithSubnavigation from "../components/NavBar";

// styles

// markup

const AddressInfo = (props) => {
  return (
    <Flex>
      <Box
        bg="#02054B"
        color="white"
        borderRadius="lg"
        my={0}
        p={{sm: 24, md: 24, lg: 4}}
      >
        <Box p={10} pb={14} pt={4}>
          <Box>
            <VStack pl={0} spacing={0} alignItems="flex-start">
              <Button
                size="md"
                height="48px"
                width="200px"
                variant="ghost"
                color="#DCE2FF"
                _hover={{border: "2px solid #1C6FEB"}}
                leftIcon={<MdPhone color="#1970F1" size="20px" />}
              >
                {props.telephone}
              </Button>
              <Button
                size="md"
                height="48px"
                width="200px"
                variant="ghost"
                color="#DCE2FF"
                _hover={{border: "2px solid #1C6FEB"}}
                leftIcon={<MdEmail color="#1970F1" size="20px" />}
              >
                {props.email}
              </Button>
              <Button
                size="md"
                height="48px"
                width="200px"
                variant="ghost"
                color="#DCE2FF"
                _hover={{border: "2px solid #1C6FEB"}}
                leftIcon={<MdLocationOn color="#1970F1" size="20px" />}
              >
                <div style={{textAlign: 'left', whiteSpace: "break-spaces", marginTop: 32}}>
                  {props.address}
                </div>
              </Button>
            </VStack>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

const ContactPageTemplate = (props) => {
  return (
    <div>
      <PageBody
        allowGradient
        content={
          <FeatureCard
            {...props}
            height={516}
            extraComponent={<AddressInfo {...props} />}
            component={<ContactForm {...props} />}
          />
        }
      />
    </div>
  );
};

const ContactPage = () => {
  const {directus} = useStaticQuery(pageQuery);
  const page = directus.contactPage;
  return (
    <Layout defaultSeo={page?.defaultSeo || false}>
      <ContactPageTemplate {...page} />
    </Layout>
  );
};

const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query ContactPage {
    directus {
      contactPage {
        id
        address
        telephone
        email
        featureContent
        mapLocation
      }
    }
  }
`;

export default ContactPage;
