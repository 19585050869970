import React from "react";

import {
  Flex,
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  useToast
} from "@chakra-ui/react";
import {MdPhone, MdOutlineEmail} from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import {stringify} from "qs";
import {serialize} from "dom-form-serializer";

export default function contact() {
  const toast = useToast();
  const handleSubmit = (e) => {
    e.preventDefault();
      const form = e.target;
    const data = serialize(form);
    if (!data.email || !data.message || !data.full_name || !data.phone) {
      toast({
        title: "Missing field(s)!",
        description: "Make sure all fields are filled in.",
        status: "error",
        position: 'top',
        duration: 7000,
        isClosable: true
      });
    }
    fetch(`${form.action}?${stringify(data)}`, {
      method: "POST"
    })
      .then((res) => {
        if (res.ok) {
          return res;
        }
        throw new Error("Network error");
      })
      .then(() => {
        form.reset();
        toast({
          title: "Message Sent.",
          description: "We'll get back to you as soon as possible.",
          status: "success",
          duration: 7000,
          position: 'top',
          isClosable: true
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  return (
    <Flex>
      <Box bg="white" borderRadius="lg" w="full">
        <Box m={8} color="#0B0E3F">
          <VStack spacing={5}>
            <form
              data-netlify=""
              action=""
              name="Contact_Form"
              style={{padding: 0, width: "100%"}}
              onSubmit={handleSubmit}
            >
              {/* {error && <ErrorMessage message={error} />} */}
              <FormControl id="full_name">
                <FormLabel >Your Name</FormLabel>
                <InputGroup borderColor="#E0E1E7">
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BsPerson color="gray.800" />}
                  />
                  <Input name="full_name" type="text" size="md" />
                </InputGroup>
              </FormControl>
              <FormControl id="email">
                <FormLabel>EMail</FormLabel>
                <InputGroup borderColor="#E0E1E7">
                  <InputLeftElement
                    pointerEvents="none"
                    children={<MdOutlineEmail color="gray.800" />}
                  />
                  <Input type="text" name="email" size="md" />
                </InputGroup>
              </FormControl>
              <FormControl id="phone">
                <FormLabel>Phone</FormLabel>
                <InputGroup borderColor="#E0E1E7">
                  <InputLeftElement
                    pointerEvents="none"
                    children={<MdPhone color="gray.800" />}
                  />
                  <Input type="text" size="md" name="phone" />
                </InputGroup>
              </FormControl>
              <FormControl id="message">
                <FormLabel>Message</FormLabel>
                <Textarea
                  name="message"
                  borderColor="gray.300"
                  _hover={{
                    borderRadius: "gray.300"
                  }}
                  placeholder="message"
                />
              </FormControl>
              <p style={{marginTop: 8, color: 'grey', fontSize: '0.8em'}}>All fields are required</p>
              <FormControl pt={4} id="name" float="right">
                <Button
                  type="submit"
                  variant="solid"
                  bg="#0D74FF"
                  color="white"
                  _hover={{}}
                >
                  Send Message
                </Button>
              </FormControl>
              <input type="hidden" name="form-name" value="Contact_Form" />
            </form>
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
}
